import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import SchutzView from '../views/SchutzView.vue'
// import AktuellesView from "../views/AktuellesView";
import HomeView from "@/views/HomeView.vue";
import NewsView from "@/views/NewsView.vue";
import ContactView from "../views/ContactView";
import SchutzView from "@/views/SchutzView";
import ImpressumView from "@/views/ImpressumView";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView

  },
  // {
  //   path: '/projekt',
  //   name: 'projekt',
  //   component: HomeView
  // },
  {
    path: '/datenschutz',
    name: 'daten',
    component: SchutzView
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
   },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView

  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
