<template>
  <v-app id="news">
    <div class="main-content">
      <v-container fluid>
        <h1 class="page-title text-center">Aktuelles</h1>
        <v-row>
          <v-col id="aktuelles">
            <p class="intro-text">
              Hier sehen Sie eine Übersicht von Veröffentlichungen über das Projekt ReMeDi:Blut. Das Projekt begann im April 2023 und wird voraussichtlich bis März 2026 laufen. Für eine transparente Öffentlichkeitsarbeit können Sie hier gesammelt alle Veröffentlichungen abrufen.
            </p>
            <v-divider></v-divider>
            <h2 class="year">Veröffentlichungen in 2024</h2>
            <v-list dense>
              <v-list-item v-for="(publication, index) in publications2024" :key="index">
                  <v-list-item-title>{{ publication.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ publication.date }}</v-list-item-subtitle>
                  <v-list-item-action>
                    <v-btn :href="publication.link" target="_blank"  class="link-btn">Hier geht es zum Beitrag</v-btn>
                  </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <h2 class="year">Veröffentlichungen in 2023</h2>
            <v-list dense>
              <v-list-item v-for="(publication, index) in publications2023" :key="index">
                  <v-list-item-title>{{ publication.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ publication.date }}</v-list-item-subtitle>
                  <v-list-item-action>
                    <v-btn :href="publication.link" target="_blank"  class="link-btn">Hier geht es zum Beitrag</v-btn>
                  </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AktuellesView',
  setup() {
    const publications2024 = [
      {
        title: 'Heise Beitrag: Mangel an Spenderblut: Wie KI-Methoden entgegenwirken können',
        link: 'https://www.heise.de/news/Mangel-an-Spenderblut-Wie-KI-Methoden-entgegenwirken-koennen-9693738.html',
        date: 'Veröffentlicht am: 22. April 2024'
      },
      {
        title: 'Ärzteblatt Beitrag: Künstliche Intelligenz in Blutbanken soll Planung unterstützen',
        link: 'https://www.aerzteblatt.de/nachrichten/150124/Kuenstliche-Intelligenz-in-Blutbanken-soll-Planung-unterstuetzen',
        date: 'Veröffentlicht am: 20. März 2024'
      },
      {
        title: 'Pressemitteilung dgti: Künstliche Intelligenz in den Blutbanken: Blutbedarf besser planbar, Auswahl von passenden Blutprodukten einfacher',
        link: 'https://www.dgti.de/fileadmin/news/pm/PM_DGTI_KI_und_Digitalisierung_F.pdf',
        date: 'Veröffentlicht am: 20. März 2024'
      },
      {
        title: 'Deutschlandfunk Beitrag: Künstliche Intelligenz in der Klinik: Wie KI Blutkonserven verwaltet',
        link: 'https://www.deutschlandfunk.de/kuenstliche-intelligenz-in-der-klinik-wie-ki-blutkonserven-verwaltet-dlf-39ec5d51-100.html',
        date: 'Veröffentlicht am: 09. Januar 2024'
      }
    ];

    const publications2023 = [
      {
        title: 'Fachhochschule Dortmund Veröffentlichung: Aufbaustart für neues Transfusions-Register',
        link: 'https://www.fh-dortmund.de/news/remedi-blut-aufbaustart-fuer-neues-transfusions-register.php',
        date: 'Veröffentlicht am: 24.05.2023'
      },
      {
        title: 'Medizinische Fakultät Universität Duisburg Essen Veröffentlichung: Millionenförderung für einmaliges Transfusionsregister: Gespendetes Blut besser verteilen',
        link: 'https://www.uni-due.de/med/meldung.php?id=1443',
        date: 'Veröffentlicht am: 08.05.2023'
      },
      {
        title: 'Abstract in Karger: ReMeDi:Blut – A future registry for medical data and antigenic properties of blood products',
        link: 'https://karger.com/tmh/article-pdf/50/Suppl.%201/1/4000383/000533306.pdf',
        date: 'Veröffentlicht: 2023'
      }
    ];

    return {
      publications2024,
      publications2023
    };
  }
});
</script>

<style scoped>
.page-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}
.intro-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.year {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
.link-btn {
  color: #1976D2; /* Customize the link color */
  font-weight: bold;
  text-transform: none;
  border: 1px solid black;
}
</style>
