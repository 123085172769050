<template>
  <v-app id="home">
    <div class="main-content">
      <v-container fluid>
        <v-col id="home">
          <h1 class="page-title text-center">ReMeDi : Blut</h1>
          <h2 class="page-h2">Ein Register für medizinische Daten und Antigeneigenschaften von Blutprodukten</h2>
          <br>
          <div class="intro">
            <div class="text">
              <p>
                Die Versorgung von Patient:innen mit Blutprodukten ist ein essenzieller Bestandteil der modernen Medizin. Bluttransfusionen werden oft benötigt, um Leben zu retten oder schwere Krankheiten zu behandeln. Gleichzeitig basiert die Versorgung mit Blutprodukten auf Spenden, wodurch ein optimaler Umgang mit dieser wertvollen Ressource sowohl medizinisch als auch ethisch geboten ist.
              </p>
            </div>
            <v-img src="Concept2.png" lazy-src="Concept_placeholder.jpg" aspect-ratio="1.7" class="small-image" @click="showImageDialog = true"></v-img>

          </div>
          <br>
          <p>
            Um auf Basis von Evidenz und KI-basierter Evaluation eine Über-, Unter- und Fehlversorgung zu vermeiden, wird ein nationales Register zur Sammlung von Daten über erfolgte Transfusionen in Deutschland benötigt. Das Projekt ReMeDi:Blut hat zum Ziel, dieses Register zu konzipieren und prototypisch aufzubauen, um zukünftig eine verbesserte Auswahl der individuell besten Blutkonserve für den jeweiligen Empfänger zu ermöglichen.<br>
            <br>
            Aktuell werden Erythrozytenkonzentrate nach relativ wenigen Kriterien patientenspezifisch zugeordnet. Durch eine genauere Charakterisierung der Blutprodukte inklusive einer molekulargenetischen Blutgruppenbestimmung, beispielsweise auf Basis von real-time-PCR oder Next-Generation-Sequencing (NGS), können zukünftig deutlich mehr Blutgruppenantigene in der Auswahl berücksichtigt werden. Eine solche Auswahl der individuell passenden Blutkonserve kann zu einer nebenwirkungsarmen und effektiven Behandlung beitragen.<br>
            <br>
            Bisher gibt es in Deutschland kein Register, welches systematisch klinische Daten zu erfolgten Transfusionen mit Blutprodukten zusammenführt und somit eine umfassende Analyse der das klinische Outcome beeinflussenden Faktoren ermöglicht. Mit ReMeDi:Blut soll durch die Konzeption und den prototypischen Aufbau eines solchen Registers zu Forschungszwecken diese Grundlage geschaffen werden. Das Register wird lokal über ein Brückenköpf (Pilot-Universitätsklinikum Essen), mit Daten versorgt und soll allen Krankenhäusern als offenes und frei zugängliches Forschungsregister zur Verfügung stehen.<br>
            <br>
            Ein zentraler Aspekt des Projekts ist die Nutzung von KI-Verfahren zur Analyse und Auswertung der Registerdaten. So können wichtige Erkenntnisse gewonnen werden, beispielsweise um in den großen Datenmengen seltene Spender zu identifizieren und Unverträglichkeiten zu vermeiden. In einem nächsten Schritt soll das Register auf eine breitere Basis gestellt werden, indem weitere Krankenhäuser und Transfusionszentren ihre Daten einbringen können. Dies wird durch den Einsatz von offenen und frei zugänglichen Schnittstellen ermöglicht. Ziel ist es, die Effizienz in der Transfusionsmedizin zu steigern und die Behandlung von Patienten mit Blutprodukten zu verbessern.<br>
            <br>
            Die Projektpartner sind das Institut für Transfusionsmedizin des Universitätsklinikums Essen als Pilotklinikum und verschiedene weitere Krankenhäuser und Transfusionszentren, die in Zukunft ihre Daten in das Register einbringen sollen. Das Projekt wird vom Bundesministerium für Bildung und Forschung gefördert.<br>
            <br>
            Insgesamt ist ReMeDi:Blut ein vielversprechendes Projekt, das eine wichtige Grundlage für die Weiterentwicklung der Transfusionsmedizin in Deutschland schafft. Durch die systematische Sammlung und Analyse von Daten soll eine bessere Behandlung von Patienten mit Blutprodukten ermöglicht werden. Auch die Anwendung von KI-Verfahren auf den Datenbestand verspricht neue Erkenntnisse und einen besseren Einsatz von Ressourcen.<br>
            <br>
            Das Bundesministerium für Bildung und Forschung (BMBF) fördert das Verbundprojekt „ReMeDi:Blut (Register für medizinische Daten und Antigeneigenschaften von Blutprodukten)“ zur Fördermaßnahme „Medizintechnische Lösungen für eine digitale Gesundheitsversorgung“ im Rahmen des Bundesprogramms „Gesundheitswirtschaft im Rahmenprogramm Gesundheitsforschung“.<br>
            <br>
          </p>
        </v-col>
      </v-container>

      <!-- Dialog für das Pop-up Bild -->
      <v-dialog v-model="showImageDialog" fullscreen>
        <v-card>
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12">
                <v-img src="Concept2.png" aspect-ratio="1.7" class="full-screen-image"></v-img>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="showImageDialog = false">Schließen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    const showImageDialog = ref(false);
    return {
      showImageDialog,
    };
  }
});
</script>

<style scoped>


.page-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}
.page-h2{
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.intro {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.text {
  flex: 1;
}
.small-image {
  width: 20%;
  height: 40vh;
  margin-left: 20px;
  cursor: pointer;
}
.full-screen-image {
  width: 90%;
  object-fit: contain;
}

</style>
