<template>
  <div>
    <nav class=" border-gray-200 px-8 sm:px-12 py-8 rounded dark:bg-gray-800">
      <div class="container flex flex-wrap justify-between items-center mx-auto">
        <!--remedi logo as link to HomeView-->
        <router-link to="/" class="flex items-center">
          <img :src="remedi_logo" class="mr-3 h-16 sm:h-14" alt="Remedi Logo">
        </router-link>
        <!--this part is only for the mobile menu-->
        <button data-collapse-toggle="mobile-menu" type="button" class="inline-flex items-center p-2 ml-3 text-lg text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul class="flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium">
            <!--here you can add different routes as navbar-->
            <li>
              <router-link to="/" class="block py-2 pr-4 pl-3 text-gray-700 rounded md:bg-transparent md:p-0 dark:text-white">Home</router-link>
            </li>
            <li>
              <router-link to="/news" class="block py-2 pr-4 pl-3 text-gray-700 rounded md:bg-transparent md:p-0 dark:text-white">Aktuelles</router-link>
            </li>
            <li>
              <router-link to="/contact" class="block py-2 pr-4 pl-3 text-gray-700 rounded md:bg-transparent md:p-0 dark:text-white">Kontakt</router-link>
            </li>
            <li>
              <a href="https://remedi-blut.fh-dortmund.de/redcap/" target="_blank" class="block py-2 pr-4 pl-3 text-red-700 rounded md:bg-transparent md:p-0 dark:text-white">REDCap</a>
            </li>
            <li>
              <a href="https://github.com/ReMeDi-Blut" target="_blank" class="block py-2 pr-4 pl-3 text-purple-700 rounded md:bg-transparent md:p-0 dark:text-white">GitHub</a>
            </li>
            <li>
              <a href="https://simplifier.net/blut-transfusionen" target="_blank" class="block py-2 pr-4 pl-3 text-orange-500 rounded md:bg-transparent md:p-0 dark:text-white">Implementation Guide</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main class="p-4">
      <!--the main content refers to the router-->
      <router-view></router-view>
    </main>
    <footer class="border-gray-200 px-6 sm:px-5 py-6 rounded dark:bg-gray-800">
      <div class="container mx-auto flex justify-between items-center">
        <p class="text-sm text-gray-600 dark:text-gray-400">© 2024 ReMeDi:Blut. Alle Rechte vorbehalten.</p>
        <p class="flex items-center space-x-4">
          <a href="/impressum" class="text-gray-600 dark:text-gray-400 hover:underline">Impressum</a>
          <a href="/datenschutz" class="text-gray-600 dark:text-gray-400 hover:underline">Datenschutz</a>
        </p>
        <img :src="bmbf" class="h-24 m-1 pa-0" alt="Bmbf Bild">
      </div>
    </footer>

  </div>
</template>

<script>
import remedi_logo from './assets/remedi_logo.png';
import bmbf from './assets/bmbf.jpg';

export default {
  name: 'App',
  components: {},

  data: () => ({
    remedi_logo,
    bmbf
  }),
}
</script>
<style>
/* change font size */
button,
button:hover,
button:focus {
  font-size: 1.1rem; /* oder jede andere gewünschte Größe */
}
nav ul li a,
nav ul li a:hover,
nav ul li a:focus {
  font-size: 1.1rem; /* oder jede andere gewünschte Größe */
}
button,
nav ul li a {
  font-weight: bold;
}
nav {
  border-bottom: 1px solid black; /* Füge den feinen schwarzen Strich hinzu */
}
footer {
  border-top: 1px solid black; /* Füge den feinen schwarzen Strich hinzu */
}
</style>