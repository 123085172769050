<template>
  <v-app id="contact">
    <div class="main-content">
      <v-container fluid>

          <h1 class="page-title text-center">Kontakt</h1>
        <p class="intro-text">
          Ihr Krankenhaus ist interessiert an einer Anbindung oder Ihr Institut ist interessiert an weitergehenden Informationen? Dann kontaktieren Sie uns via Mail oder nutzen Sie unser Kontaktformular.
        </p>
        <v-row>
          <v-col cols="12" md="6">
            <div class="contact-info">
              <h2>University of Applied Sciences and Arts Dortmund</h2>
              <p>
                Fachbereich Informatik<br>
                Emil-Figge-Straße 42<br>
                44227 Dortmund
              </p>
              <img src="@/assets/FHLogo.jpg" class="logo" alt="FH Dortmund Logo">
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="contact-info">
              <h2>Institute for Transfusion Medicine, University Hospital Essen</h2>
              <p>
                Universitätsklinikum Essen<br>
                Transplantationsdiagnostik & FuE<br>
                im Robert-Koch-Haus<br>
                Virchowstr. 179<br>
                45147 Essen
              </p>
              <img src="src/assets/Essen.jpg" alt="UK Essen Logo" class="logo">
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="intro-text">
              Schreiben Sie uns unter remedi-blut[at]fh-dortmund.de
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactView",
  data() {
    return {
      form: {
        name: "",
        email: "",
        institution: "",
        message: ""
      }
    };
  },

});
</script>

<style scoped>
.page-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.intro-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.5;
  color: #555;
}

.contact-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%; /* Setzt die Höhe auf 100% */
}

.contact-info h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-info p {
  margin-bottom: 0;
  color: #333;
}

.contact-info a {
  color: #1976D2;
  font-weight: bold;
  text-decoration: none;
}

.logo {
  max-height: 100px;
  width: auto;
  margin-top: 20px;
}
</style>

