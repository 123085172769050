<template>
  
    <div class id="datenschutzerklaerung">
      <h1 class="page-title">Datenschutzerklärung</h1>

      <p>
            Wir freuen uns über Ihr Interesse an unserer Webseite. 
            Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. 
            Nachfolgend informieren wir Sie über die Erhebung, Verarbeitung und Nutzung Ihrer Daten im Rahmen der Nutzung unserer Webseite.
          </p>

          <h2><strong>Verantwortlicher</strong></h2>
          <p>
            Verantwortlich für die Datenverarbeitung auf dieser Webseite ist:
            <br />
            Fachhhochschule Dortmund<br />
            Emil-Figge-Str. 42<br />            
            remedi-blut[at]fh-dortmund.de <br />
          </p>

          <h2><strong>Allgemeine Informationen</strong></h2>
          <p>
            Unsere Webseite dient rein informativen Zwecken. Beim Besuch unserer Webseite erheben wir keine personenbezogenen Daten, 
            es sei denn, Sie stellen uns diese freiwillig zur Verfügung. 
            Wir verwenden keine Kontaktformulare oder Anmeldefunktionen, die eine Eingabe personenbezogener Daten erfordern.
          </p>

          <h2><strong>Erhebung und Speicherung von Daten</strong></h2>
          <p>
            Beim Besuch unserer Webseite werden durch den Webserver automatisch Informationen in sogenannten Server-Log-Dateien erhoben und gespeichert. Diese Daten sind technisch notwendig, um Ihnen unsere Webseite anzuzeigen und gewährleisten Stabilität und Sicherheit. Diese Informationen umfassen:
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Browsertyp und Browserversion</li>
              <li>Referrer-URL (die zuvor besuchte Seite)</li>
              <li>Hostname des zugreifenden Rechners</li>
            </ul>
            Diese Daten lassen keine unmittelbaren Rückschlüsse auf Ihre Person zu. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Rechtsgrundlage für die vorübergehende Speicherung dieser Daten ist Art. 6 Abs. 1 lit. f DSGVO, unser berechtigtes Interesse an der Darstellung und Verbesserung unserer Webseite.
          </p>

          <h2><strong>Cookies</strong></h2>
          <p>
            Unsere Webseite verwendet keine Cookies zur Speicherung von personenbezogenen Daten. Sollte es zukünftig zu einem Einsatz von Cookies kommen, werden wir Sie darüber informieren und entsprechende Zustimmungsmechanismen implementieren.
          </p>

          <h2><strong>Weitergabe von Daten</strong></h2>
          <p>
            Es erfolgt keine Weitergabe der erhobenen Daten an Dritte, es sei denn, wir sind gesetzlich dazu verpflichtet oder dies ist im Rahmen der Erfüllung gesetzlicher Pflichten erforderlich.
          </p>

          <h2><strong>Ihre Rechte</strong></h2>
          <p>
            Sie haben das Recht:
            <ul>
              <li>Gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.</li>
              <li>Gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</li>
              <li>Gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist.</li>
              <li>Gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            </ul>
          </p>

          <h2><strong>Änderungen der Datenschutzerklärung</strong></h2>
          <p>
            Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern, 
            insbesondere um sie an rechtliche Änderungen oder neue Funktionen der Webseite anzupassen. Die jeweils aktuelle Version finden Sie auf dieser Webseite.
          </p>

          <h2><strong>Kontakt</strong></h2>
          <p>
            Wenn Sie Fragen zu dieser Datenschutzerklärung haben oder Ihre Rechte geltend machen möchten, 
            können Sie uns unter den oben genannten Kontaktdaten erreichen.
          </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";



export default defineComponent({
  name: "SchutzView",
  setup() {
    return {
      slider2: 50,
    };
  },

});
</script>
<style scoped>
.page-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

</style>