<template>
  <v-app>
    <div class="main-content">
  <v-container fluid>
    <v-col cols="12" id="impressum">
      <h1 class="page-title">Impressum</h1>
      <v-row>
        <v-col cols="12" sm="4">
          <p>
            <strong>Angaben gemäß § 5 TMG:</strong><br>
            Prof. Dr. Britta Böckmann<br>
            Fachhhochschule Dortmund<br>
            Emil-Figge-Str. 42<br>
            44227 Dortmund<br>
            Deutschland
          </p>
          <p>
            <strong>Kontakt:</strong><br>
            E-Mail: remedi-blut[at]fh-dortmund.de 
          </p>          
          <p>
            <strong>Technische und inhaltliche Verantwortung für die Website:</strong><br>
            Johannes Wutzkowsky | Kamil Lorek<br>
            Emil-Figge-Str. 42 <br>
            44227 Dortmund <br>
            Deutschland <br>
            E-Mail: johannes.wutzkowsky[at]fh-dortmund.de | kamil.lorek[at]fh-dortmund.de
          </p>
          <p>
            <strong>Aufsichtsbehörde:</strong><br>
            Ministerium für Kultur und Wissenschaft des Landes Nordrhein-Westfalen (MKW NRW) <br>
          </p>
          <p>
            <strong>Haftung für Inhalte:</strong><br>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            <strong>Haftung für Links:</strong><br>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
          </p>          
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</div>


  </v-app>
</template>

<script>
export default {
  name: "ImpressumView"
}
</script>

<style scoped>
.page-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}
</style>